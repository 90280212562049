.MobileNavbarContainer {
    font-size: smaller;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    background-color: var(--purple);
    color: #fff;
    padding: 0.2rem;
}

.MobileNavbarLogo {
    width: 30px;
    height: 40px;
}

.MobileNavbarLinks {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
}

.MobileNavbarLink {
    margin: 0 1rem 0 1rem;
    color: white;
}

.MobileNavbarHamburger {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 12px;
}

.hamburger-icon {
    height: 20px;
    width: 20px;
    margin-left: 2px;
}

.CloseButton {
    height: 25px;
    width: 25px;
    margin-left: 2px;
}

.MobileOverlayHide {
    display: none;
}

.MobileOverlayShow {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    line-height: 2;
}

.MobileOverlay {
    height: 110vh;
    justify-content: space-evenly;
    position: absolute;
    z-index: 999;
    width: 100%;
}

.hide {
    display: none;
}