.searchBarContainer {
    display: flex;
    position: relative;
    /* justify-content: center; */
    align-items: center;
    background-color: var(--bg-white);
    border: 0;
    padding: 0.3rem;
    min-width: 250px;
    border: 1px solid #E7E7E7;
    border-radius: 10px;
    margin: 0 0 1rem 0;
}

.searchIcon {
    display: flex;
    justify-content: flex-start;
    padding-left: 0.3rem;
    position: absolute;
}

.input {
    display: flex;
    flex: 1;
    border: 0;
    color: grey;
    font-size: 0.75rem;
    background-color: var(--bg-white);
    text-align: center;
    padding: 0.8rem;
    /* height: 100%; */
    border-radius: 10px;
}

.input:focus {
    box-shadow: none;
}

.scrollableBlock {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    padding: 0;
    position: relative;
}

.scrollableBlock>* {
    position: absolute;
    left: 50%;
    transform: translate(-50%);
}

.shortCodeInput {
    display: flex;
    font-size: 0.7rem;
    text-align: center;
    justify-content: center;
    align-items: center;
    background-color: white;
    flex: 1;
    border: 1px solid lightblue;
    box-shadow: 2px 2px 5px lightblue;
    border-radius: 5px;
    height: 25px;
    max-width: 120px;
    padding: 2px;
}


@media (max-width: 630px) {
    .scrollableBlock {
        padding-right: 5px;
        padding-left: 5px;
    }
}