.staff-manager-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    padding-top: 0;
}

.staff-manager-header {
    font-size: 24px;
    margin-bottom: 20px;
}

.staff-manager-form {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
}

.staff-manager-input {
    margin-right: 10px;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.staff-manager-button {
    padding: 5px 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.staff-manager-button:hover {
    background-color: #0056b3;
}

.staff-manager-list {
    display: flex;
    flex-direction: column;
    width: 100%;
    font-size: 0.7rem;
}

.staff-manager-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #ccc;
}

.staff-manager-item span {
    flex-grow: 1;
}

.staff-manager-item button {
    margin-left: 10px;
    padding: 5px 10px;
    background-color: #dc3545;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.staff-manager-item button:hover {
    background-color: #c82333;
}

@media (max-width: 630px) {

    .staff-manager-form {
        flex-wrap: wrap;
        gap: 0.5rem;
    }

}