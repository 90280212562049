.optionsContainer {
    display: flex;
    margin: 2rem 0;
    justify-content: space-between;
    gap: 4rem;
}

.button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.8rem 1rem;
    border-radius: 10px;
    font-size: 0.8rem;
    cursor: pointer;
}

.SaveButton {
    background-color: var(--light-purple);
    color: white;
    margin: 2rem 0;
    width: 50%;
}

.SaveButton:hover {
    background-color: var(--purple);
}

.AddPatientsBtn {
    background-color: #00AC4F;
    color: white;
}

.AddPatientsBtn:hover {
    background-color: #05c45e;
}

.DeletePatientsBtn {
    background-color: #DA4036;
    color: white;
}

.DeletePatientsBtn:hover {
    background-color: #f84c3f;
}

.SharedVisitsBtn {
    background-color: #0F5FC2;
    color: white;
}

.SharedVisitsBtn:hover {
    background-color: #1172e9;
}

.VisitCodesBtn {
    background-color: #0fc2c0;
    color: white;
}

.VisitCodesBtn:hover {
    background-color: #12dfdd;
}

.SubmitChargesBtn {
    background-color: var(--light-purple);
    color: white;
    max-height: 50px;
    align-self: flex-end;
}

.SubmitChargesBtn:hover {
    background-color: var(--purple);
}

.OptionButtonsIcon {
    height: 18px;
    width: 18px;
    margin-right: 0.2rem;
}

.selectInputField {
    background-color: var(--whitesmoke);
    border-radius: 5px;
    color: black;
}

.partA {
    display: flex;
    gap: 0.5rem;
    justify-content: center;
}

.actionsBtnText {
    text-align: center;
}

.option {
    background-color: var(--whitesmoke);
}

.select {
    display: flex;
    background-color: var(--whitesmoke);
    padding: 0 0.5rem;
    border-radius: 5px;
}

.tableContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
}

.flexTable {
    position: absolute;
    display: flex;
    flex-direction: column;
    font-size: 0.7rem;
    height: 100%;
    width: 100%;
    overflow-y: scroll;
}

.checkbox {
    display: flex;
    justify-content: center;
    align-items: center;
}

.flexRow {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid var(--container-grey);
}

.flexCell {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 8px;
    cursor: pointer;
}

.TableHeader {
    color: var(--font-grey);
}

.border {
    /* display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--container-grey);
    padding: 0;
    margin: 0; */
}

.eyeIcon {
    height: 15px;
    width: 15px;
    margin-right: 5px;
}

.flexName {
    flex: 0.4
}

.dateText {
    color: black;
    font-weight: medium;
}

@media (max-width: 630px) {
    .optionsContainer {
        gap: 0.7rem;
        margin: 1rem 0;
        justify-content: center;
    }

    .whiteContainer {
        padding-bottom: 2.5rem;
    }

    .flexTable {
        font-size: 0.55rem;
    }

    .flexCell {
        padding: 0.4rem 0;
    }

    .tableContainer {
        margin: unset;
    }

    .button {
        font-size: 0.6rem;
        padding: 0.4rem;
    }

    .OptionButtonsIcon {
        height: 20px;
        width: 20px;
        margin-right: 0;
        padding: 2px;
    }

    .HideMobile {
        display: none;
    }

    .SaveButton {
        margin: 1rem 0;
    }

    .flexName {
        flex: 0.3
    }

    .dateText {
        font-size: 0.6rem;
    }

    .SubmitChargesBtn {
        height: 45px;
        padding: 15px;
    }
}

@media (min-width: 630px) {
    .HideOnDesktop {
        display: none;
    }
}