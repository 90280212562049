.colorBlue {
    color: #008CBA;
}

.textClass {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    font-size: 16px;
    min-height: 50px;
}

.headerContainer {
    display: flex;
    gap: 0.3rem;
}

.buttonClass {
    background-color: #008CBA;
    color: white;
    padding: 10px 15px;
    margin: 5px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    margin-top: 0;
    margin-left: 0;
}

.buttonClass:hover {
    background-color: #007BB5;
}

.mainContainer {
    width: 100%;
    max-width: 1600px;
    margin: auto;
    background: white;
    padding: 20px;
    height: 100%;
    border-radius: 8px;
    overflow-y: auto;
    margin-bottom: 2rem;
}

.container {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;
}

.error-message {
    color: red;
    font-size: 14px;
}

.editButtons button {
    margin-right: 5px;
    padding: 5px 10px;
    font-size: 14px;
}

.editInput {
    width: 100%;
    padding: 5px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    font-size: 14px;
}

.saveButton,
.cancelButton {
    margin-top: 5px;
    padding: 5px 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
}

.saveButton {
    background-color: #008CBA;
    color: white;
}

.cancelButton {
    background-color: #ccc;
    color: #333;
    margin-right: 5px;
}

.success-message {
    color: green;
    font-size: 14px;
    margin-top: 5px;
}

.info-message {
    color: blue;
    font-size: 14px;
    margin-top: 5px;
}

.top-button {
    margin-bottom: 10px;
}

li {
    background: #eee;
    margin: 10px 0;
    padding: 10px;
    border-radius: 4px;
    color: black;
    overflow-x: auto;
}

.accordionMainContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: rgb(0, 140, 186);
    cursor: pointer;
    flex-wrap: wrap;
    gap: 0.5rem;
}

.subContainer {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.accordionSubContainer {
    display: flex;
    width: unset;
    align-items: center;
}

.buttonContainer {
    display: flex;
    align-items: center;
}

/* mobile */
@media (max-width: 768px) {
    .expansions {
        gap: 0;
        justify-content: center;
    }

    .buttonClass {
        font-size: 0.8rem;
    }
}