
.listContainer::-webkit-scrollbar {
  width: 5px;
  /* Thin scrollbar width */
}

.listContainer::-webkit-scrollbar-track {
  background: #f3f4f6;
  /* Track color */
  border-radius: 5px;
}

.listContainer::-webkit-scrollbar-thumb {
  background: #bbb;
  /* Scrollbar handle color */
  border-radius: 5px;
}

.listContainer::-webkit-scrollbar-thumb:hover {
  background: #999;
  /* Darker on hover */
}

/* For Firefox */
.listContainer {
  scrollbar-width: thin;
  scrollbar-color: #bbb #f3f4f6;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f3f4f6;
  border-radius: 8px;
  padding: 5px;
  width: 96%;
}
/* Category Title */
.categoryTitle {
  font-size: 12px;
  font-weight: bold;
  color: #333;
  margin-top: 8px;
  padding-bottom: 4px;
  border-bottom: 1px solid #ddd;
  display: block;
}
/* Unified Box */
.box {
  display: flex;
  /* width: 60%; */
  background-color: white;
  padding: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

/* Two Equal Columns */
.column {
  flex: 1;
  padding: 16px;
  border-right: 2px solid #ddd;
  /* Creates a clear division */
}

/* Remove border from the last column */
.column:last-child {
  border-right: none;
}

/* Title */
.title {
  font-size: 16px;
  font-weight: bold;
  border-bottom: 2px solid #ddd;
  padding-bottom: 8px;
}

/* Search Bar */
.searchBar {
  border: 1px solid #ccc;
  padding: 8px;
  border-radius: 4px;
  margin-top: 8px;
  width: 100%;
}

/* List Container */
.listContainer,
.selectedContainer {
  margin-top: 8px;
  overflow-y: auto;
  height: 160px;
  border: 1px solid #ccc;
  padding: 8px;
  background-color: #f9f9f9;
}

.selectedContainer {
  height: 198px;
}

.listContainer p,
.selectedContainer p {
  margin: 4px 0;
  font-size: 12px;
}

@media (max-width: 630px) {
  .container {
    width: 86%;
  }
}