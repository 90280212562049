.mainContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100vh;
    margin-left: 50px;
}

.heading {
    padding: 10px;
    color: black;
}

.whiteContainer {
    display: flex;
    flex: 1;
    flex-direction: column;
    color: black;
    width: 100%;
    height: 100%;
}

.inputField {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    background-color: var(--bg-white);
    border: 0;
    border-radius: 10px;
    padding: 6px;
    border: 1px solid #E7E7E7;
}

.input {
    border: 0;
    color: grey;
    font-size: 0.7rem;
    background-color: var(--bg-white);
    text-align: center;
    padding: 5px;
}

.input::placeholder {
    color: var(--font-grey);
}

.searchIcon {
    display: flex;
    position: absolute;
    top: 16%;
    left: 2%;
    width: 20px;
    margin-right: 10px;
    padding-bottom: 1px;
}

.topContainer {
    display: flex;
    margin-top: 10px;
    margin-bottom: 10px;
    justify-content: space-evenly;
}

.topContainer1 {
    display: flex;
    margin-top: 1rem;
    margin-bottom: 1rem;
    justify-content: flex-end;
}

.tableContainer {
    overflow-x: auto;
    overflow-y: visible;
    font-size: 0.75rem;
    font-family: 'Roboto Condensed', sans-serif;
}

.flexTable {
    display: table;
    width: max-content;
    min-width: 100%;
    border-collapse: separate;
    border-spacing: 0;
}

.sortIndicator {
    cursor: pointer;
}

.flexRow {
    display: table-row;
}

.flexRow {
    transition: background-color 0.2s ease;
}

.flexRow:hover {
    background-color: rgba(0, 0, 0, 0.05);
    cursor: pointer;
}

.flexCell {
    display: table-cell;
    padding: 10px 0;
    padding-right: 0.5rem;
    vertical-align: middle;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border-bottom: 1px solid #eee;
}

.flexCell:nth-child(13) {
    max-width: 120px;
}

.flexRow:first-child .flexCell {
    font-weight: 600;
}

.sortContainer {
    display: flex;
    background-color: var(--bg-white);
    font-size: 0.7rem;
    align-items: center;
    padding: 5px 10px;
    border-radius: 10px;
}

.select {
    display: flex;
    border: none;
    outline: none;
    color: black;
    font-weight: 600;
    font-size: 0.8rem;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: var(--bg-white) url('data:image/svg+xml;utf8,<svg fill="black" height="12" viewBox="0 0 24 24" width="12" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/></svg>') no-repeat right 10px center;
    background-size: 1.2rem;
    min-width: 100px;
    text-align: center;
    -moz-text-align-last: center;
    text-align-last: center;
}

.select option {
    background-color: var(--bg-white);
}

.m5 {
    margin: 5px;
}

.listIcons {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    height: 15px;
    width: 15px;
    margin-left: 8px;
}

.button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.8rem 1rem;
    border-radius: 10px;
    font-size: 0.8rem;
    cursor: pointer;
}

.AddConsultsBtn {
    background-color: #00AC4F;
    color: white;
}

.AddConsultsBtn:hover {
    background-color: #05c45e;
}

.OptionButtonsIcon {
    height: 18px;
    width: 18px;
    margin-right: 0.2rem;
}

.toggleContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.4rem;
    font-size: 0.8rem;
}

.toggleSwitch {
    display: flex;
    width: 80px;
    height: 25px;
    border-radius: 15px;
    padding: 5px;
    cursor: pointer;
    position: relative;
    transition: background-color 0.3s ease, justify-content 0.3s ease;
}

.toggleSwitch.active {
    background-color: green;
    justify-content: flex-end;
}

.toggleSwitch.inactive {
    background-color: gray;
    justify-content: flex-start;
}

.toggleButton {
    width: 15px;
    height: 15px;
    background-color: white;
    border-radius: 50%;
    transition: transform 0.3s ease;
}

.toggleLabel {
    position: absolute;
    width: 100%;
    text-align: center;
    font-size: 10px;
    color: white;
    z-index: 1;
}

.columnSelectorBtn {
    background: var(--table-purple);
    color: white;
    padding: 4px 8px;
    border: none;
    border-radius: 4px;
    margin: 4px 0;
    font-size: 12px;
}

.mobileColumnSelector {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: white;
    z-index: 1000;
    padding: 20px;
    overflow-y: auto;
}

.selectorHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 15px;
    border-bottom: 1px solid #eee;
    margin-bottom: 15px;
    cursor: pointer;
}

.selectorHeader h4 {
    margin: 0;
    color: #333;
}

.selectorHeader button {
    background: var(--table-purple);
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
}

.columnOptions {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.columnOption {
    display: flex;
    align-items: center;
    padding: 10px;
    background: #f8f9fa;
    border-radius: 4px;
}

.columnOption label {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
    font-size: 14px;
    color: #333;
}

.columnOption input[type="checkbox"] {
    width: 18px;
    height: 18px;
    accent-color: var(--table-purple);
}

.mobileColumnSelector {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    z-index: 1001;
    /* Increased z-index */
    padding: 25px;
    border-radius: 8px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
    width: 400px;
    max-height: 80vh;
    overflow-y: auto;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000;
}

.columnSelectorBtn {
    background: var(--table-purple);
    color: white;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    margin: 4px 8px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.columnSelectorBtn:hover {
    opacity: 0.9;
}

.editableInput {
    width: 90%;
    padding: 4px 4px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 12px;
}

.editableInput:focus {
    outline: none;
    border-color: var(--purple);
    box-shadow: 0 0 0 2px rgba(var(--purple-rgb), 0.1);
}

.filterBar {
    display: flex;
    gap: 10px;
    margin: 6px 0;
    padding: 0 2rem;
    align-items: center;
}

.filterField {
    max-width: 200px;
    width: 100%;
}

.filterInput,
.filterSelect {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
}

.filterSelect {
    background-color: white;
}

.clearFiltersBtn {
    padding: 0.5rem 1rem;
    background-color: var(--purple);
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
}

.totalsRow {
    border-top: 2px solid #E7E7E7;
    background-color: #f8f9fa;
    font-weight: 600;
    margin-bottom: 20px;
}

.totalsCell {
    flex: 1;
}

.totalsCellMobile {
    flex: 0.6;
}

.iconCell {
    flex: 0.1;
    margin-right: 4px;
}

.filterIcon {
    width: 20px;
    height: 20px;
}

.tableContainer {
    position: relative;
    overflow-y: auto;
    margin-bottom: 20px;
}

.totalCounter {
    position: sticky;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: white;
    border-top: 2px solid #E7E7E7;
    padding: 4px 32px;
    z-index: 10;
    margin-top: auto;
    font-size: 14px;
}

/* mobile */
@media (max-width: 630px) {

    .mainContainer {
        padding: 10px;
        margin-left: 0;
    }

    .whiteContainer {
        padding-bottom: 2.5rem;
    }

    .HideMobile {
        display: none;
    }

    .flexTable {
        font-size: 0.6rem;
    }

    .flexTable {
        min-width: var(--table-width);
    }

    .tableContainer {
        -webkit-overflow-scrolling: touch;
        scrollbar-width: thin;
        scrollbar-color: #888 #f1f1f1;
        padding-bottom: 5rem;
    }

    .topContainer {
        margin: unset;
        gap: unset;
        margin-bottom: 0.5rem;
        margin-top: 0.5rem;
        flex-wrap: wrap;
    }

    .AddConsultsBtn {
        margin: 0;
        padding: 0 0.6rem;
        font-size: 0.7rem;
    }

    .OptionButtonsIcon {
        height: 10px;
        width: 10px;
    }

    .button {
        padding: 0 0.6rem;
        font-size: 0.75rem;
    }

    .toggleContainer {
        margin-top: 0.5rem;
    }

    .mobileColumnSelector {
        transform: none;
        width: 100%;
        top: 0;
        left: 0;
        max-height: unset;
    }

    .filterBar {
        padding: 0 .35rem;
        gap: 2px;
    }

    .clearFiltersBtn {
        font-size: 12px;
        padding: 6px 10px;
    }

    .totalBadge {
        font-size: 12px;
        padding: 2px 8px;
        min-width: 60px;
    }

    .filterIcon {
        width: 60px;
    }

    .totalCounter {
        padding: 4px 12px;
    }
}

/* Desktop */
@media (min-width: 630px) {
    .HideOnDesktop {
        display: none;
    }
}