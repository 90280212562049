.DesktopNavbarContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    padding: 0.4rem;
    background-color: var(--purple);
    color: white;
    width: 100%;
}

.NavbarLogo {
    width: 40px;
    height: 50px;
}

.NavbarLinks {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
}

.NavbarLink {
    margin: 0 1rem 0 1rem;
    color: white;
}