.directoryUtilitiesContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-height: calc(100vh - 35px - 60px);
    padding: 2rem;
    margin-left: 50px;
    /* overflow-x: hidden; */
}

.directoryMainContainer {
    width: 100%;
    max-width: 1600px;
    margin: auto;
    background: white;
    padding: 20px;
    height: 100%;
    border-radius: 8px;
    /* overflow-y: auto; */
    margin-bottom: 2rem;
    border-radius: 30px;
}

.directoryWhiteContainer {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.topContainer {
    display: flex;
    margin-top: 2rem;
    margin-bottom: 2rem;
    justify-content: space-evenly;
}

.topContainer1 {
    display: flex;
    margin-top: 1rem;
    margin-bottom: 1rem;
    justify-content: flex-end;
}

.directory-container {
    font-family: Arial, sans-serif;
    margin: 20px;
    height: calc(100vh - 180px);
    display: flex;
    flex-direction: column;
}

.directory-searchHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #ddd;
    padding: 10px 0;
}

.directory-searchInput {
    flex: 1;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.directory-resultsInfo {
    margin-right: 20px;
    color: #555;
}

.directory-actions {
    display: flex;
    align-items: center;
}

.directory-actions a {
    margin-right: 20px;
    color: #0078d7;
    text-decoration: none;
}

.directory-sort {
    display: flex;
    align-items: center;
}

.directory-sort label {
    margin-right: 5px;
}

.directory-list {
    flex: 1;
    overflow-y: auto;
}

.directory-card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 10px;
    margin-bottom: 10px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    border-left: 4px solid;
}

.directory-avatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
}

.directory-info {
    flex: 1.5;
    display: flex;
    align-items: center;
}

.directory-position {
    flex: 1;
    text-align: left;
    justify-content: center;
    display: flex;
    flex-direction: column;
}

.position-title {
    font-weight: 600;
}

.directory-info h4 {
    margin: 0;
    font-size: 16px;
}

.directory-name {
    margin-left: 20px;
    color: black;
}

.directory-name .mobile-view-title {
    display: none;
}

.directory-name .mobile-view-phone {
    display: none;
}

.directory-info .directory-username {
    display: none;
}

.directory-info .desktop-view-company-name {
    color: #d9534f;
    font-size: 14px;
}

.directory-contact {
    flex: 1;
    text-align: right;
    word-wrap: break-word;
    justify-content: center;
    display: flex;
    flex-direction: column;
}

.directory-pagination {
    display: flex;
    justify-content: center;
    padding-bottom: 20px;
}

.directory-loader {
    text-align: center;
    padding: 40px;
    font-size: 18px;
}

.shimmer {
    background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
    background-size: 200% 100%;
    animation: shimmer 1.5s infinite linear;
}

@keyframes shimmer {
    0% {
        background-position: -200% 0;
    }

    100% {
        background-position: 200% 0;
    }
}

/* mobile */
@media (max-width: 630px) {

    .directoryUtilitiesContainer {
        padding: 0.5rem;
        margin-left: 0;
        padding-bottom: 1.9rem
    }

    .directoryMainContainer {
        padding: 0;
    }

    .mainContainer {
        padding: 10px;
        margin-left: 0;
    }

    .whiteContainer {
        padding-bottom: 2.5rem;
    }

    .directory-contact {
        display: none;
    }

    .directory-info {
        align-items: center;
        margin-left: 0;
    }

    .directory-name .mobile-view-title {
        display: block;
        font-weight: 600;
        color: var(--font-grey);
    }

    .directory-name .mobile-view-phone {
        display: block;
        font-weight: 600;
        color: var(--font-grey);
    }

    .directory-position {
        display: none;
    }

    .directory-info .directory-username {
        color: #d9534f;
        font-size: 14px;
        display: block;
    }

    .directory-info .desktop-view-company-name {
        display: none;
    }

}

@media (min-width: 630px) and (max-width: 840px) {

    .directory-contact {
        display: none;
    }

    .directory-info {
        align-items: center;
        margin-left: 0;
    }

    .directory-name .mobile-view-title {
        display: block;
        font-weight: 600;
        color: var(--font-grey);
    }

    .directory-name .mobile-view-phone {
        display: block;
        font-weight: 600;
        color: var(--font-grey);
    }

    .directory-info .desktop-view-company-name {
        display: none;
    }

    .directory-position {
        display: none;
    }

    .directory-info .directory-username {
        color: #d9534f;
        font-size: 14px;
        display: block;
    }

}

@media (min-width: 840px) and (max-width: 1024px) {
    .directory-info {
        align-items: center;
        margin-left: 0;
    }

    .directory-name .mobile-view-title {
        display: block;
        font-weight: 600;
        color: var(--font-grey);
    }

    .directory-name .mobile-view-phone {
        display: block;
        font-weight: 600;
        color: var(--font-grey);
    }

    .directory-position {
        display: none;
    }
}