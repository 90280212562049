.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

.subContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 25%;
  padding-bottom: 3rem;
}

.columnContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
  align-items: center;
}

.selectInputField {
  background-color: var(--whitesmoke);
  border-radius: 5px;
  color: black;
  padding: 10px 10px;
  height: 45px;
  text-align: center;
  text-align-last: center;
  border: 0;
  outline: 0;
  width: 96%;
}

.inputWrapper {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  width: 100%;
  align-items: center;
}

.required {
  color: red;
}

.dateField {
  background-color: var(--whitesmoke);
  color: black;
  border: 0;
  border-radius: 5px;
  padding: 15px 10px;
  height: 45px;
  text-align: center;
  text-align-last: center;
  outline: none;
  font-size: 0.8rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 96%;
}

.rectangleBtn {
  display: block;
  /* width: 250px; */
  width: 96%;
  padding: 15px 10px;
  font-size: 0.8rem;
  text-align: center;
  border-radius: 5px;
  background-color: var(--light-purple);
  color: white;
  cursor: pointer;
  border: 0;
  height: 45px;
}

.rectangleBtn:hover {
  background-color: var(--purple);
}

.dateField::placeholder {
  text-align: center;
  text-align-last: center;
}

.inputField {
  background-color: var(--whitesmoke);
  border: 0;
  border-radius: 5px;
  padding: 15px 10px;
  text-align: center;
  outline: none;
  height: 45px;
  width: 250px;
}

.checkboxContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.7rem;
}

/* mobile */
@media (max-width: 630px) {
  .inputField {
    padding: 15px 3px;
    font-size: 0.8rem;

  }

  .selectInputField {
    width: 86%;
  }

  .dateField,
  .inputField,
  .rectangleBtn {
    width: 86%;
  }
}