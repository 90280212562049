.noteContainer {
  display: flex;
  margin-left: 10px;
  gap: 1rem;
  justify-content: space-between;
  align-items: flex-start;
}

.macroMate {
  flex: 4;
  height: 100%;
}

.macroMateMain {
  width: 100%;
  height: 90%;
}

.noteForm {
  flex: 2;
}

@media (max-width: 768px) {
  .noteContainer {
    flex-direction: column;
    gap: 1rem;
    margin-top: 30px;
  }

  .macroMate {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .macroMate,
  .noteForm {
    width: 100%;
    flex: 1;
  }

  .macroMateMain {
    width: 86%;
  }
}