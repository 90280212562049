.scheduler-container {
    height: 100vh;
    max-width: 1000px;
    width: 100vw;
}

@media (max-width: 630px) {
    .dhx_scheduler_month .dhx_cal_navline {
        padding: 12px 0;
        flex-wrap: unset;
        font-size: 12px !important;
    }

    .dhx_scheduler_week .dhx_cal_navline {
        padding: 12px 0;
        flex-wrap: unset;
        font-size: 12px !important;
    }

    .dhx_scheduler_week .dhx_cal_date {
        width: 90px;
        margin-right: unset;
        margin-left: unset;
    }

    .dhx_cal_tab_segmented.dhx_cal_tab_first {
        margin-left: unset;
    }

    .dhx_cal_tab {
        font-size: 12px;
    }

    .dhx_cal_prev_button,
    .dhx_cal_next_button {
        min-width: unset;
        width: 20px !important;
    }

    .dhx_cal_prev_button:before {
        font-size: 20px;
    }

    .dhx_cal_next_button:before {
        font-size: 20px;
    }

    .dhx_cal_navline_flex .dhx_cal_date {
        font-size: 12px;
    }

    .dhx_cal_today_button {
        margin-right: 0;
    }

    .dhx_cal_light {
        width: unset !important;
    }

    .dhx_cal_light .dhx_cal_larea .dhx_cal_radio {
        gap: 2px;
    }
}