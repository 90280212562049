.sidebarContainer {
    position: relative;
    top: 0;
    display: flex;
    flex-direction: column;
    width: 200px;
    justify-content: space-around;
    align-items: center;
    background-color: white;
    height: 100vh;
    transition: width 0.1s ease-in-out;
    z-index: 999;
    border-right: 2px solid var(--container-grey);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.LeftArrowIcon {
    position: absolute;
    right: -16px;
    top: 25px;
    height: 32px;
    width: 32px;
    background-color: var(--light-purple);
    border-radius: 100px;
    z-index: 999;
}

.collapsed {
    width: 50px;
    height: 100vh;
}

.headingIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
}

.topHeading {
    position: absolute;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 28px;
    color: black;
    margin-top: 20px;
}

.downArrowHalf {
    align-self: center;
    height: 6px;
}

.LeftArrowIcon:hover {
    cursor: pointer;
    background-color: var(--purple);
}

.userDetails {
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: space-evenly;
    align-self: stretch;
    width: 100%;
    padding-bottom: 20px;
}

.user {
    font-size: 10px;
    padding-left: 10px;
}

.profilepic {
    display: flex;
    flex-direction: row;
}

.userAvatar {
    height: 30px;
}

.logo {
    width: 120px;
}

.test {
    display: flex;
    align-self: stretch;
    justify-content: center;
}

li {
    list-style: none;
}

.menuList {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    color: var(--font-grey);
    font-size: 14px;
    font-weight: 300;
}

.MenuItemWrapper {
    position: relative;
    width: 100%;
    background-color: white;
    display: flex;
    border-radius: 5px;
    padding: 5px 3px 5px 3px;
    max-width: 210px;
    min-width: 130px;
    cursor: pointer;
}

.MenuItemWrapper:hover {
    background-color: #eeeeee;
}

.menuItemText {
    padding-left: 5px;
    align-self: center;
}

.userName {
    font-weight: 600;
    color: black;
}

.userDesignation {
    font-size: 8px;
    color: var(--font-grey);
    font-weight: 400;
}

.hide {
    display: none;
}

.activeMenu {
    background-color: var(--purple);
    color: white;
}

.activeMenu:hover {
    background-color: var(--purple);
}

.collapsed .MenuItemWrapper {
    margin: 0;
    min-width: unset;
}

.icon {
    background-color: var(--icon-bg-grey);
    border-radius: 5px;
}

/* mobile */
@media (max-width: 630px) {

    .MenuItemWrapper {
        margin-left: 15px;
        margin-right: 15px;
        background-color: var(--purple);
        min-width: unset;
    }

    .userDetails {
        display: none;
    }

    .menuList {
        flex-direction: row;
    }

    .sidebarContainer {
        flex-direction: row;
        transition: none;
        position: fixed;
        top: unset;
        bottom: 0;
        height: 50px;
        width: 100%;
        z-index: 100;
    }

    .menuItemText {
        display: none;
    }

    .topHeading {
        display: none;
    }

    .LeftArrowIcon {
        display: none;
    }

    .collapsed {
        border-radius: 50px 50px 0 0;
        background-color: var(--purple);
    }

    .activeMenu {
        background-color: white;
    }

    .activeMenu:hover {
        background-color: white;
    }

}

/* Desktop */
@media (min-width: 740px) {}