.mainContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100vh;
    margin-left: 50px;
}

.heading {
    padding: 10px;
    color: black;
}

.whiteContainer {
    display: flex;
    flex: 1;
    flex-direction: column;
    color: black;
    width: 100%;
    height: 100%;
}

.inputField {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    background-color: var(--bg-white);
    border: 0;
    border-radius: 10px;
    padding: 6px;
    border: 1px solid #E7E7E7;
}

.input {
    border: 0;
    color: grey;
    font-size: 0.7rem;
    background-color: var(--bg-white);
    text-align: center;
    padding: 5px;
}

.input::placeholder {
    color: var(--font-grey);
}

.searchIcon {
    display: flex;
    position: absolute;
    top: 16%;
    left: 2%;
    width: 20px;
    margin-right: 10px;
    padding-bottom: 1px;
}

.topContainer {
    display: flex;
    margin-top: 2rem;
    margin-bottom: 2rem;
    justify-content: space-evenly;
}

.topContainer1 {
    display: flex;
    margin-top: 1rem;
    margin-bottom: 1rem;
    justify-content: flex-end;
}

.feedbackTextarea {
    width: 100%;
    min-height: 200px;
    padding: 15px;
    margin: 20px 0;
    border: 1px solid #ddd;
    border-radius: 8px;
    font-size: 16px;
    resize: vertical;
    font-family: inherit;
}

.feedbackTextarea:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
}

.fileInput {
    display: block;
    margin: 20px 0;
    padding: 10px;
    width: 100%;
    border: 1px dashed #ddd;
    border-radius: 8px;
    background-color: #f8f9fa;
}

.submitButton {
    background-color: var(--light-purple);
    color: white;
    padding: 12px 24px;
    border: none;
    border-radius: 6px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.2s;
    max-width: 200px;
}

.submitButton:hover {
    background-color: var(--purple);
}

.submitButton:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}

.feedbackContainer {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
}

.feedbackTitle {
    color: #333;
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: 600;
}

.categorySelect {
    width: 100%;
    padding: 12px;
    margin: 20px 0;
    border: 1px solid #ddd;
    border-radius: 8px;
    font-size: 16px;
    background-color: white;
}

.categorySelect:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
}


/* mobile */
@media (max-width: 630px) {

    .mainContainer {
        padding: 10px;
        margin-left: 0;
    }

    .whiteContainer {
        padding-bottom: 2.5rem;
    }

}