.dashboard-container {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    margin: 1.5rem 0;
    background-color: white;
    border-radius: 15px;
}

.stat-main {
    display: flex;
    padding: 2rem 0;
}

.dash-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #D3FFE7;
    border-radius: 100px;
    margin-right: 0.8rem;
    height: 50px;
    height: 50px;
}

.border {
    border-left: 1px solid var(--whitesmoke);
    height: 65%;
}

.stat-details {
    display: flex;
    flex-direction: column;
    line-height: 1.2rem;
}

.stat-info {
    font-size: 0.6rem;
    color: var(--font-grey)
}

.stat-count {
    color: black;
    font-weight: 500;
    font-size: 1.2rem;
}

@media (max-width: 630px) {
    .dashboard-container {
        margin-top: 0.5rem;
        margin-bottom: 1rem;
    }
}