.mainContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100vh;
    padding: 2rem;
    margin-left: 50px;
    overflow-x: hidden;
}

.whiteContainer {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 30px;
    width: 100%;
    height: 100%;
    margin-top: 1rem;
    padding-bottom: 5rem;
    padding-top: 2rem;
}

.utilitiesContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 3rem;
    align-items: center;
    height: 100%;
    margin-top: 1.5rem;
    max-width: 500px;
    font-size: 0.8rem;
}

.optionContainer {
    display: flex;
    justify-content: space-between;
    min-width: 280px;
    cursor: pointer;
}

.options {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
}

.heading {
    display: flex;
    align-self: center;
    padding: 10px;
    color: black;
}

.navigationContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
}

.active {
    gap: unset;
}

@media (max-width: 630px) {

    .mainContainer {
        padding: 10px;
        margin-left: 0;
    }

    .whiteContainer {
        padding-bottom: 9rem;
        margin-top: 0;
    }

    .utilitiesContainer {
        min-width: 100%;
        width: 100%;
    }
}