.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1rem;
    height: 100%;
}

.detailsContainer {
    display: flex;
    flex-wrap: wrap;
    max-width: 600px;
}

.inputFieldContainer {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 0 0 50%;
}

.newAdmissionContainer {
    width: 100%;
    max-width: 1000px;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 1rem;
}

.backButtonContainer {
    width: 100%;
    display: flex;
    padding-left: 10%;
}

.navigationContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
}

/* mobile */
@media (max-width: 630px) {
    .container {
        margin: 0;
        height: calc(100% - 160px);
    }

    .detailsContainer {
        border: none;
        border-radius: 0;
        box-shadow: none;
        transition: none;
        align-items: center;
        width: 100%;
        height: calc(100% - 50px);
        overflow-y: auto;
    }

    .container {
        justify-content: center;
    }

    .inputFieldContainer {
        width: 100%;
    }

}

@media (max-width: 352px) {
    .inputFieldContainer {
        flex: 1;
    }

    .selectInputField {
        min-width: 300px;
    }
}