.toast {
    position: fixed;
    bottom: 20px;
    left: 51.5%;
    transform: translateX(-50%);
    padding: 12px 24px;
    border-radius: 4px;
    color: #fff;
    font-size: 16px;
    z-index: 9999;
    text-align: center;
    animation: fadeIn 0.3s, fadeOut 0.3s 2.7s;
}

.toast.success {
    background-color: #28a745;
}

.toast.error {
    background-color: #dc3545;
}

@media (max-width: 630px) {
    .toast {
        padding: 8px 3px 8px 3px;
        font-size: 12px;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translateX(-50%) translateY(20px);
    }

    100% {
        opacity: 1;
        transform: translateX(-50%) translateY(0);
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
        transform: translateX(-50%) translateY(0);
    }

    100% {
        opacity: 0;
        transform: translateX(-50%) translateY(20px);
    }
}