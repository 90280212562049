.mainContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100vh;
    margin-left: 50px;
    overflow-x: hidden;
}

.heading {
    padding: 10px;
    color: black;
}

.whiteContainer {
    display: flex;
    flex: 1;
    flex-direction: column;
    background-color: white;
    color: black;
    border-radius: 30px;
    width: 100%;
    height: 100%;
    max-width: 1000px;
}

.inputField {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    background-color: var(--bg-white);
    border: 0;
    border-radius: 10px;
    padding: 6px;
    border: 1px solid #E7E7E7;
}

.input {
    border: 0;
    color: grey;
    font-size: 0.7rem;
    background-color: var(--bg-white);
    text-align: center;
    padding: 5px;
}

.input::placeholder {
    color: var(--font-grey);
}

.searchIcon {
    display: flex;
    position: absolute;
    top: 16%;
    left: 2%;
    width: 20px;
    margin-right: 10px;
    padding-bottom: 1px;
}

.topContainer {
    display: flex;
    margin-top: 2rem;
    margin-bottom: 2rem;
    justify-content: space-evenly;
}

.topContainer1 {
    display: flex;
    margin-top: 1rem;
    margin-bottom: 1rem;
    justify-content: flex-end;
}

.tableContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
}

.flexTable {
    position: absolute;
    display: flex;
    flex-direction: column;
    font-size: 0.7rem;
    height: 98%;
    width: 100%;
    overflow-y: scroll;
}

.flexRow {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid var(--container-grey);
}

.flexRow {
    transition: background-color 0.2s ease;
}

.flexRow:hover {
    background-color: rgba(0, 0, 0, 0.05);
    cursor: pointer;
}

.flexCell {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 8px;
    cursor: pointer;
}

.sortContainer {
    display: flex;
    background-color: var(--bg-white);
    font-size: 0.7rem;
    align-items: center;
    padding: 5px 10px;
    border-radius: 10px;
}

.select {
    display: flex;
    border: none;
    outline: none;
    color: black;
    font-weight: 600;
    font-size: 0.8rem;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: var(--bg-white) url('data:image/svg+xml;utf8,<svg fill="black" height="12" viewBox="0 0 24 24" width="12" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/></svg>') no-repeat right 10px center;
    background-size: 1.2rem;
    min-width: 100px;
    text-align: center;
    -moz-text-align-last: center;
    text-align-last: center;
}

.select option {
    background-color: var(--bg-white);
}

.m5 {
    margin: 5px;
}

.listIcons {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    height: 15px;
    width: 15px;
    margin-left: 8px;
}

.button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.8rem 1rem;
    border-radius: 10px;
    font-size: 0.8rem;
    cursor: pointer;
}

.AddPatientsBtn {
    background-color: #00AC4F;
    color: white;
}

.AddPatientsBtn:hover {
    background-color: #05c45e;
}

.OptionButtonsIcon {
    height: 18px;
    width: 18px;
    margin-right: 0.2rem;
}

.toggleContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.4rem;
    font-size: 0.8rem;
}

.toggleSwitch {
    display: flex;
    /* align-items: center; */
    width: 80px;
    height: 25px;
    border-radius: 15px;
    padding: 5px;
    cursor: pointer;
    position: relative;
    transition: background-color 0.3s ease, justify-content 0.3s ease;
}

.toggleSwitch.active {
    background-color: green;
    justify-content: flex-end;
}

.toggleSwitch.inactive {
    background-color: gray;
    justify-content: flex-start;
}

.toggleButton {
    width: 15px;
    height: 15px;
    background-color: white;
    border-radius: 50%;
    transition: transform 0.3s ease;
}

.toggleLabel {
    position: absolute;
    width: 100%;
    text-align: center;
    font-size: 10px;
    color: white;
    z-index: 1;
}

.tooltip {
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
}

.tooltipText {
    visibility: hidden;
    width: 200px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 5px;
    padding: 5px 10px;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -100px;
    opacity: 0;
    transition: opacity 0.3s;
}

.tooltip:hover .tooltipText {
    visibility: visible;
    opacity: 1;
}

.DeletePatientsBtn {
    color: white;
    cursor: pointer;
    padding: 8px 16px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 0.2s;
}

.DeletePatientsBtn:hover {
    opacity: 0.9;
}

.filterBar {
    display: flex;
    gap: 10px;
    margin: 6px 0;
    align-items: center;
}

.filterField {
    max-width: 200px;
    width: 100%;
}

.filterInput,
.filterSelect {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
}

.filterSelect {
    background-color: white;
}

.clearFiltersBtn {
    padding: 0.5rem 1rem;
    background-color: var(--purple);
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
}

.filterIcon {
    width: 20px;
    height: 20px;
}

/* mobile */
@media (max-width: 630px) {
    .hoverContent {
        transform: translate(-50%, 10px);
        pointer-events: auto;
    }

    .mainContainer {
        padding: 10px;
        margin-left: 0;
    }

    .whiteContainer {
        padding-bottom: 2.5rem;
    }

    .HideMobile {
        display: none;
    }

    .flexTable {
        font-size: 0.6rem;
    }

    .flexCell {
        padding: 0.4rem 0;
    }

    .tableContainer {
        margin: unset;
    }

    .topContainer {
        margin: unset;
        gap: unset;
        margin-bottom: 0.5rem;
        margin-top: 0.5rem;
        flex-wrap: wrap;
    }

    .AddPatientsBtn {
        margin: 0;
        padding: 0 0.6rem;
        font-size: 0.7rem;
    }

    .OptionButtonsIcon {
        height: 10px;
        width: 10px;
    }

    .button {
        padding: 0 0.6rem;
        font-size: 0.75rem;
    }

    .toggleContainer {
        margin-top: 0.5rem;
    }

    .filterBar {
        gap: 2px;
    }

    .clearFiltersBtn {
        font-size: 12px;
        padding: 6px 10px;
    }

    .filterIcon {
        width: 60px;
    }

}

/* Desktop */
@media (min-width: 630px) {
    .HideOnDesktop {
        display: none;
    }
}