.tableWrapper {
    overflow: auto;
    border-radius: 8px;
    background: white;
    height: 100%;
    padding: 1rem;
}

.tableContainer {
    display: flex;
    flex-direction: column;
    min-width: 1800px;
}

.headerRow {
    display: flex;
    background-color: var(--table-purple);
    position: sticky;
    top: 0;
    z-index: 1;
    border-radius: 10px 10px 0 0;
}

.headerCell {
    padding: 0.4rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    color: #ffffff;
    justify-content: center;
    font-weight: 500;
    font-size: 0.7rem;
}

.tableBody {
    flex: 1;
}

.tableRow {
    display: flex;
    border-bottom: 1px solid #eee;
    cursor: pointer;
    transition: background-color 0.2s;
}

.tableRow:hover {
    background-color: #f5f5f5;
}

.tableCell {
    padding: 0.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
    font-size: 0.7rem;
}

@media (max-width: 630px) {
    .tableWrapper {
        padding-top: 0;
    }
}