.mainContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100vh;
    padding: 2rem;
    margin-left: 50px;
    overflow-x: hidden;
}

.diagnosisContainer {
    margin-bottom: 1.5rem;
    margin-top: 3rem;
    display: flex;
    width: 100%;
    justify-content: center;
}

.heading {
    padding: 10px;
    color: black;
}

.whiteContainer {
    display: flex;
    flex: 1;
    flex-direction: column;
    background-color: white;
    border-radius: 0 0 30px 30px;
    width: 100%;
    overflow-y: scroll;
}

.tabsContainer {
    display: flex;
    flex-direction: row;
    min-height: 50px;
    background-color: var(--tabs-grey);
    justify-content: space-evenly;
    align-items: center;
}

.tab {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.activeTab {
    border-top: 5px solid var(--purple);
    background-color: white;
    color: black;
}

.detailsTab {
    border: 1px solid #9197B3;
}

.facesheetTab {
    border: 1px solid #9197B3;
}

.chargesTab {
    border: 1px solid #9197B3;
}

.facesheetContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    text-align: center;
    margin: 10px;
}

.content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.viewBtn {
    display: flex;
    width: 100%;
    background-color: var(--light-purple);
    color: white;
    max-width: 100px;
    justify-content: center;
    border-radius: 5px;
    cursor: pointer;
}

.actionBtns {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 50%;
    gap: 0.5rem;

}

.viewBtn:hover {
    background-color: var(--purple);
    color: white;
}

.facesheetRow {
    display: flex;
    justify-content: space-between;
    width: 100%;
    border: 1px solid var(--font-grey);
    border-radius: 5px;
    padding: 2px 4px;
    align-items: center;
}

.subContent {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 25%;
    padding-bottom: 3rem;
}

.btnContainer {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.columnContainer {
    display: flex;
    gap: 0.4rem;
    align-items: baseline;
}

.inputField {
    background-color: var(--whitesmoke);
    border: 0;
    border-radius: 5px;
    padding: 15px 10px;
    text-align: center;
    outline: none;
    height: 45px;
    width: 250px;
}

.select {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 189px;
}

.selectInputField {
    background-color: var(--whitesmoke);
    border-radius: 5px;
    color: black;
    padding: 10px 10px;
    height: 45px;
    text-align: center;
    text-align-last: center;
    border: 0;
    outline: 0;
    width: 250px;
}

.dateField {
    background-color: var(--whitesmoke);
    color: black;
    border: 0;
    border-radius: 5px;
    padding: 15px 10px;
    height: 45px;
    text-align: center;
    text-align-last: center;
    outline: none;
    font-size: 0.8rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 250px;
}

label {
    margin-left: auto;
    margin-right: auto;
    font-size: 0.7rem;
    margin-bottom: 0;
    margin-top: 1rem;
}

.dateField::placeholder {
    text-align: center;
    text-align-last: center;
}

.inputWrapper {
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
}

.rowContainer {
    display: flex;
    flex-direction: row;
}

.inactiveButton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 50px;
    background-color: var(--tabs-grey);
    border-radius: 10px;
    text-align: center;
    cursor: pointer;
}

.activeButton {
    background-color: var(--purple);
    color: white;
}

.rectangleBtn {
    display: block;
    width: 50%;
    height: 200px;
    padding: 7px;
    font-size: 0.8rem;
    text-align: center;
    border-radius: 5px;
    background-color: var(--light-purple);
    color: white;
    margin-bottom: 2rem;
    cursor: pointer;
}

.rectangleBtn:hover {
    background-color: var(--purple);
}

.required {
    color: red;
}

.checkboxContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
    font-size: 0.7rem;
}

.requiredFieldEmpty {
    border: 1px solid #ff4444 !important;
}

.requiredFieldEmptyVisitType {
    border: 1px solid #ff4444 !important;
    border-radius: 10px;
}

.summaryPage {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    height: 550px;
}

.summaryPageNavigation {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}

/* mobile */
@media (max-width: 630px) {

    .mainContainer {
        padding: 10px;
        margin-left: 0;
    }

    .btnContainer {
        width: 80%;
        margin-top: 20px;
    }

    .inputField {
        padding: 15px 3px;
        font-size: 0.8rem;
    }

    .inactiveButton {
        width: 150px;
    }

    .pb200 {
        padding-bottom: 200px
    }

    #ChargesHistory {
        font-size: 0.8rem;
    }

    .selectInputField,
    .inputField,
    .dateField {
        width: 166.6px;
    }

    .summaryPage {
        max-width: 500px;
        height: 450px;
    }

}

/* desktop */
@media (min-width: 631px) {}