.file-explorer .toolbar {
    max-height: 50px;
    height: 50px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    overflow-x: auto;
    white-space: nowrap;
    padding: 10px;
    scroll-behavior: smooth;
    justify-content: space-between;
}

.UtilitiesContainer .file-explorer {
    font-weight: 300 !important;
    width: 100%;
    max-width: 1600px;
    margin: auto;
    background: white;
    padding: 20px;
    height: 100%;
    border-radius: 8px;
    margin-bottom: 2rem;
    border-radius: 30px;
    overflow: hidden;
}

.files-container .navigation-pane {
    width: 40% !important;
}

.fm-modal.dialog {
    position: fixed;
    top: 10%;
    transform: translate(-50%, -50%);
    max-width: 1000px;
    margin: 0 auto;
    z-index: 1000;
}

.fm-modal video {
    width: 100%;
    height: auto;
}

.files-container .folders-preview .files .fm-context-menu {
    top: 190px !important;
    left: 20px !important;
}

.files .file-item .rename-file-container.list {
    top: -2px;
}

.fm-context-menu .file-context-menu-list ul li.active {
    overflow-x: unset;
}

.fm-modal img {
    max-height: 80vh;
    object-fit: contain;
    width: auto;
    margin: 0 auto;
    display: block;
}

@media screen and (max-width: 630px) {
    .UtilitiesContainer .file-explorer {
        padding: unset;
    }

    .file-explorer .toolbar {
        max-height: 50px;
        height: 50px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        overflow-x: auto;
        white-space: nowrap;
        padding: 10px;
        scroll-behavior: smooth;
    }

    .fm-toolbar>div:nth-child(2) {
        display: none;
    }

    .files-container .navigation-pane {
        width: unset !important;
    }

    .files-container .folders-preview {
        width: 100%;
    }

    .fm-modal.dialog {
        width: 90% !important;
        position: fixed;
        top: 30%;
        transform: translate(-50%, -50%);
        max-width: 1200px;
        margin: 0 auto;
        z-index: 1000;
    }

    .fm-modal video {
        width: 100%;
        height: auto;
    }

}