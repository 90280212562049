.columnContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: 0.8rem;
    color: black;
}

.DiagnosisContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    align-items: center;
    padding: 5px;
    padding-top: 15px;
}

.selectedDiagnosisContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    padding: 0 5px;
}

.selectedDiagnosis {
    border-top: 1px solid var(--container-grey);
    padding-top: 2px;
}

.selectedDiagnosis:last-child {
    border-bottom: 1px solid var(--container-grey);
    padding-bottom: 2px;
}

.DiagnosisItem {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    font-size: 0.6rem;
    border: 1px solid rgb(199, 196, 196);
    border-top: none;
    gap: 0.7rem;
    width: 100%;
}

.DiagnosisItemCode {
    display: flex;
    padding: 5px;
}

.DiagnosisItemDescription {
    display: flex;
    flex: 1;
    align-self: center;
    justify-content: center;
}

.checkmarkIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    cursor: pointer;
}

.DiagnosisBlock {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5px;
    border-radius: 5px;
    width: 100%;
    padding-bottom: 1rem;
}

.accordionTitle {
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background-color: var(--light-purple);
    color: white;
    padding: 0.5rem;
    border-radius: 10px 10px 0 0;
    cursor: pointer;
}

.plus {
    color: white;
    font-size: 1rem;
}

.accordionTitle:hover {
    background-color: var(--purple);
}

.diagnosisHeading {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    gap: 1rem;
}

.button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.8rem 1rem;
    border-radius: 10px;
    font-size: 0.8rem;
    cursor: pointer;
}

.SaveButton {
    background-color: var(--light-purple);
    color: white;
    margin: 2rem 0;
    width: 50%;
}

.SaveButton:hover {
    background-color: var(--purple);
}