.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 500px;
    width: 100%;
    justify-content: center;
    gap: 1rem;
}

.diagnosisHeading {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    gap: 1rem;
}

.addButton {
    display: flex;
    background-color: var(--light-purple);
    color: white;
    flex-direction: row;
    width: 100%;
    border-radius: 5px;
    justify-content: center;
    padding: 5px;
    cursor: pointer;
    max-width: 20%;
}

.addButton:hover {
    background-color: var(--purple);
}

.DiagnosisContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 550px;
    width: 100%;
    align-items: center;
    padding: 5px;
    padding-top: 15px;
}

.selectedDiagnosisContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    flex: 1;
    padding: 0 5px;
    text-align: left;
}

.selectedDiagnosis {
    border-top: 1px solid var(--container-grey);
    padding-top: 2px;
}

.selectedDiagnosis:last-child {
    border-bottom: 1px solid var(--container-grey);
    padding-bottom: 2px;
}

.DiagnosisItem {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    font-size: 0.6rem;
    border: 1px solid rgb(199, 196, 196);
    border-top: none;
    gap: 0.7rem;
    width: 100%;
}

.DiagnosisItemCode {
    display: flex;
    padding: 5px;
}

.DiagnosisItemDescription {
    display: flex;
    flex: 1;
    align-self: center;
    justify-content: center;
}

.checkmarkIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    cursor: pointer;
}

.DiagnosisBlock {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5px;
    border-radius: 5px;
    width: 100%;
    padding-bottom: 1rem;
}

.accordionTitle {
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background-color: var(--light-purple);
    color: white;
    padding: 0.5rem;
    border-radius: 10px 10px 0 0;
    cursor: pointer;
}

.plus {
    color: white;
    font-size: 1rem;
}

.accordionTitle:hover {
    background-color: var(--purple);
}

.saveButton {
    display: flex;
    background-color: var(--light-purple);
    color: white;
    flex-direction: row;
    width: 100%;
    border-radius: 5px;
    justify-content: center;
    padding: 5px;
    cursor: pointer;
}

.saveButton:hover {
    background-color: var(--purple);
}

.searchContainer {
    display: flex;
    justify-content: center;
    position: relative;
}

.searchIcon {
    position: absolute;
    top: 49%;
    left: 10%;
    transform: translate(-50%, -50%);
}

.inputContainer {
    display: flex;
    margin: 10px;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
}

.inputField {
    display: flex;
    background-color: var(--whitesmoke);
    border: 0;
    border-radius: 5px;
    padding: 15px 10px 15px 10px;
    text-align: center;
}

.mainContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
    height: 100%;
}

.search-container,
.diagnosisRightContainer {
    max-height: 370px;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.diagnosisRightContainer {
    margin-top: 80px;
}

.diagnosisRightContainer h4 {
    font-size: 1.2rem;
    margin-bottom: 10px;
    color: #333;
    text-align: center;
}

.notesTextArea {
    width: 100%;
    height: 200px;
    resize: none;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 12px;
    font-size: 0.9rem;
    color: #333;
    outline: none;
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
}

.notesTextArea::placeholder {
    color: #aaa;
}

.convertButton {
    margin-top: 15px;
    width: 100%;
    padding: 12px 20px;
    background-color: #5e3ae4;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s ease;
}

.convertButton:hover {
    background-color: #4b2dbf;
}

@media (max-width: 630px) {

    .DiagnosisContainer {
        padding: 0px;
        min-width: unset;
    }

    .inputField {
        padding: 0.6rem;
        font-size: 0.7rem;
    }

    .container {
        max-width: unset;
    }

    .mainContainer {
        flex-direction: column;
        gap: 15px;
    }

    .search-container,
    .diagnosisRightContainer {
        width: 100%;
        padding: 15px;
    }

    .diagnosisRightContainer {
        margin-top: unset;
        max-height: 100%;
    }

    .convertButton {
        font-size: 0.9rem;
        padding: 10px;
    }
}